<template>
  <div>
    <b-overlay :show="showTableOverlay" rounded="sm">
      <b-card style="max-width: 100%" class="mb-2">
        <div class="table-filters">
          <b-input-group class="mb-9">
            <CompactSuggestion
              suggestion-processor="userSuggestionProcessor"
              style="min-width: 30%"
              v-model="filterUserId"
              :placeholder="$t('COMMON.USER')"
              ref="userSuggestionProcessorRef"
            >
            </CompactSuggestion>

            <range-date-picker
              id="field-date"
              :start-date.sync="startDate"
              :end-date.sync="endDate"
              with-time
            ></range-date-picker>

            <b-input-group-append>
              <b-button
                size="sm"
                text="Search"
                variant="success"
                @click="onFilterSearch"
                >{{ $t("COMMON.SEARCH") }}</b-button
              >
              <b-button
                size="sm"
                text="Clear"
                variant="secondary"
                @click="onFiltersClear"
                >{{ $t("COMMON.CLEAR") }}</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>

        <b-table
          id="table"
          striped
          bordered
          :items="response.data"
          @sort-changed="sortingChanged"
          no-local-sorting
          responsive
          selectable
          select-mode="single"
          :fields="fields"
          @row-selected="onRowsSelected"
        ></b-table>

        <b-dropdown
          id="dropdown-show-by"
          :text="$t('COMMON.SHOW_BY') + ': ' + this.perPage"
          class="m-md-2"
          offset="-50"
          style="float: right"
        >
          <b-dropdown-item
            v-for="count in showPerPageArray"
            :key="count"
            @click="showPerPageChanged(count)"
            >{{ count }}</b-dropdown-item
          >
        </b-dropdown>

        <div
          v-bind:style="
            response.data.length === 0 || itemsTotal < perPage
              ? 'display:none;'
              : null
          "
        >
          <b-pagination
            v-model="currentPage"
            :page-class="!calculate ? 'hidden' : null"
            :last-class="!calculate ? 'hidden' : null"
            :first-class="!calculate ? 'hidden' : null"
            :hide-ellipsis="!calculate"
            :size="!calculate ? 'lg' : null"
            :per-page="perPage"
            @change="onPageSelected"
            :total-rows="itemsTotal"
            aria-controls="table"
          >
          </b-pagination>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import Common from "../../common";
import ApiService from "@/core/services/api.service";
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RangeDatePicker from "../component/RangeDatePicker";
import CompactSuggestion from "../component/CompactSuggestion";

export default {
  name: "Logs",
  components: {
    CompactSuggestion,
    RangeDatePicker,
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          formatter: Common.idObjectFormatter,
          sortingKey: "el.id",
          sortable: false,
        },
        {
          key: "created",
          formatter: Common.idObjectFormatter,
          label: this.$t("LOGS.CREATED"),
          sortingKey: "el.created",
          sortable: true,
        },
        {
          key: "method",
          label: this.$t("LOGS.METHOD"),
          sortingKey: "el.method",
          sortable: true,
        },
        {
          key: "userName",
          label: this.$t("COMMON.USER"),
          sortingKey: "el.user.id",
          sortable: false,
        },
      ],
      getList: {
        resource: "/api/logs/requests",
        requestType: "GET",
        requestParams: {
          enrich: true,
          teamId: ApiService.teamData.value,
        },
      },
      response: {
        data: [],
        totalCount: 1,
      },
      showTableOverlay: false,

      currentPage: 1,
      perPage: 10,
      showPerPageArray: [10, 50, 100],

      filterUserId: null,
      startDate: null,
      endDate: null,
      date: {
        startDt: null,
        endDt: null,
      },
      calculate: false,
      selectedItem: null,
    };
  },
  created() {
    this.getList.requestParams.count = this.perPage;
    this.getList.requestParams.calculate = this.calculate;
    //this.prepareTimeZone();
    this.loadList();
  },
  computed: {
    itemsTotal() {
      return this.response.totalCount;
    },
  },
  mounted: async function () {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.LOGS") }]);
  },
  methods: {
    loadList: function () {
      this.showTableOverlay = true;
      this.$store
        .dispatch(API_REQUEST, this.getList)
        .then((response) => {
          this.response = Common.processEntityListResponse(
            this.response,
            response,
            this.currentPage,
            this.perPage,
            this.calculate
          );
          this.currentPage = this.response.noData
            ? this.currentPage - 1
            : this.currentPage;
          this.showTableOverlay = false;
        })
        .catch(this.onError);
    },
    sortingChanged: function (ctx) {
      this.currentPage = 1;
      this.getList.requestParams.sortDir = ctx.sortDesc ? "desc" : "asc";

      let field = this.fields.find((x) => x.key === ctx.sortBy);
      if (field != null && field.sortingKey != null) {
        this.getList.requestParams.sortField = field.sortingKey;
      } else {
        this.getList.requestParams.sortField = ctx.sortBy;
      }
      this.getList.requestParams.start = 0;
      this.loadList();
    },
    onPageSelected: function (index) {
      this.getList.requestParams.start = (index - 1) * this.perPage;
      this.loadList();
    },

    onRowsSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedItem = items[0];

        this.$bus.$emit("dropdown_actions:animate");
      } else {
        this.selectedItem = null;
      }
    },
    showPerPageChanged: function (count) {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = count;
      this.perPage = count;
      this.loadList();
    },
    onFilterSearch: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;

      this.getList.requestParams["userId"] = this.filterUserId
        ? this.filterUserId
        : null;
      this.getList.requestParams.startDate = this.startDate
        ? this.startDate
        : null;
      this.getList.requestParams.endDate = this.endDate
        ? this.addDays(this.endDate, 1).toISOString()
        : null;

      this.loadList();
    },

    onFiltersClear: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = this.perPage;

      delete this.getList.requestParams["userId"];
      this.filterUserId = null;
      this.$refs.userSuggestionProcessorRef.clearValue();

      delete this.getList.requestParams["startDate"];
      this.startDate = null;

      delete this.getList.requestParams["endDate"];
      this.endDate = null;

      this.loadList();
    },
    getLang() {
      return localStorage.getItem("language") || "en";
    },
    addDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },

    suggestFormater: function (s) {
      let str = s.name;
      if (s.description) {
        str = str + "\n(" + s.description + ")";
      }
      return str;
    },
  },
};
</script>

<style scoped></style>
